<template>
  <div class="container">
    <h2>{{ editMode ? "Edit" : "Add" }} Dealer Event</h2>
    <button @click="saveEvent()" type="button" class="btn btn-success">Save</button>
    <button @click="cancel()" type="button" class="btn btn-warning">Cancel</button>
    <button v-if="editMode" @click="deleteEvent()" type="button" class="btn btn-danger">Delete</button>
    <button v-if="editMode" @click="downLoadReport()" type="button" class="btn btn-success">Download Report</button>



    <div class="row">
      <div class="col-md-12">
        <label for="Description">Event Description</label>
        <textarea class="form-control" id="Description" v-model="event.Description" required></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label for="EventDate">Dealer</label>

        <DealerSelect v-model="event.DealerId" required />

      </div>

      <div class="col-md-4">
       
        <label for="EventDate">Event Date</label>
        <input class="form-control" id="EventDate" v-model="event.EventDate" required type="date" />
      </div>
      <div class="col-md-4">
        <label for="EventTime">Event Time</label>
        <input class="form-control" id="EventTime" v-model="event.EventTime" type="time" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <label for="RSVPEndDate">RSVP End Date</label>
        <input class="form-control" id="RSVPEndDate" v-model="event.RSVPEndDate" required type="date" />
      </div>
      <div class="col-md-6">
        <label for="EventPAX">Maximum Number of Guests</label>
        <input class="form-control" id="EventPAX" v-model="event.EventPAX" type="number" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">

        <ImageUploader label="Banner" v-model="event.ImageUrl" />


      </div>
      <div class="col-md-6">

        <ImageUploader label="Mobile banner" v-model="event.MobileImageUrl" />

      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label for="EmailCopy">Email Copy</label>
        <textarea class="form-control" id="EmailCopy" v-model="event.EmailCopy" required></textarea>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12 d-flex align-items-center">
        <div class="field">
          <label for="ShowEvent" class="mr-3">Show Event</label>
          <b-form-checkbox id="ShowEvent" v-model="event.ShowEvent"></b-form-checkbox>
        </div>
      </div>
    </div>
    <button @click="saveEvent()" type="button" class="btn btn-success">Save</button>
    <button @click="cancel()" type="button" class="btn btn-warning">Cancel</button>
    <button v-if="editMode" @click="deleteEvent()" type="button" class="btn btn-danger">Delete</button>
    <button v-if="editMode" @click="downLoadReport()" type="button" class="btn btn-success">Download Report</button>



  </div>
</template>

<script>


import DealerSelect from "@/components/custom/DealerSelect.vue";
import ImageUploader from "@/components/custom/ImageUploader.vue";
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
export default {
  name: "DealerEventForm",
  components: {
    DealerSelect, ImageUploader,miniToastr
  },
  props: ["editingDealers", "editMode"],
  data() {
    return {
      event: {
        DealerId: '',
        EventDate: '',
        EventTime: '',
        RSVPEndDate: '',
        EventPAX: null,
        ImageUrl: '',
        MobileImageUrl: '',
        EmailCopy: '',
        Description: '',
        ShowEvent: true
      },
      
    };
  },

  created() {
     
    if (this.editMode) {
      
      this.event = JSON.parse(JSON.stringify(this.editingDealers));
      this.event.EventDate = this.event.EventDate.split('T')[0];
      this.event.RSVPEndDate = this.event.RSVPEndDate.split('T')[0];
    }
  },
  mounted() {
 
  },
  methods: {

    downLoadReport(){

      var payload = {
        data: {eventId: this.event.EventId},      
        success: (response) => {
         
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getDealerEventRSVPReport", payload);
    },
    saveEvent() {
      
      // TODO: Implement event save logic
      var data = JSON.parse(JSON.stringify(this.event));
      var payload = {
        data: data,      
        success: (response) => {
          if (this.editMode) {
            this.$emit("editDealersSuccess");
          } else {
            this.$emit("addDealersSuccess");
          }
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      if (this.editMode) {
        this.$store.dispatch("editDealerEvents", payload);
      } else {
        this.$store.dispatch("addDealerEvents", payload);
      }
    },
    cancel() {
      this.$emit('closeEditDealers'); 
    
      //close the modal

    },
    deleteEvent() {
      // TODO: Implement delete logic
      this.$emit('delete'); 
      console.log('Deleting Event', this.event.EventId);
    }
  }
};
</script>

<style scoped>
.field{
  display: flex;
  flex-direction: row;
}
.row{
  margin-bottom: 15px;
}
</style>