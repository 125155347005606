<template>
  <div class="wrapper">
    <label v-if="label" class for="Image">{{ label }}</label>
    <div v-if="!editingImage" class="image-container" :class="{ dark: dark }">
      <img
        v-if="isImage()"
        v-bind:src="getImageSource()"
        class="previewImage"
      />
      <span class="not-image" v-if="!isImage() && value">
        {{ value }}
      </span>
      <!-- <button @click="editImage()" type="button" class="btn btn-success">
        Update Image
      </button> -->

      <Spinner v-if="isLoading"></Spinner>

      <a v-if="!isImage() && value" target="_blank" :href="getImageSource()">
        <font-awesome-icon class="pdf" icon="file-pdf" />
      </a>
      <label :for="labelId">
        <font-awesome-icon class="folder" icon="folder-open" />
      </label>
      <div @click="removeImage()">        
        <font-awesome-icon class="trash" icon="trash" />
      </div>
    </div>
    <div v-if="true">
      <!-- <button @click="updateImage()" type="button" class="btn btn-success">
        Update
      </button> -->

      <font-awesome-icon @click="removeImage()" class="trash" icon="trash" />
      <input
        type="file"
        :id="labelId"
        class="input-file"
        @change="handleChanges($event.target.name, $event.target.files)"
      />
      <div class="error">
        {{ error }}
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/custom/spinner.vue";

const INITIAL = 0,
  LOADING = 1,
  SUCCESS = 2,
  FAILED = 3;

export default {
  name: "ImageUploader",

  data() {
    return {
      editingImage: false,
      error: "",
      status: null,
      output: [],
      labelId: null,
      isLoading: false,
    };
  },
  components: {
    Spinner,
  },
  created() {
    this.labelId = `label${Math.random()}`;
  },
  props: [
    "value",
    "label",
    "imageFileName",
    "allFiles",
    "width",
    "height",
    "dark",
    "pdf",
  ],

  methods: {
    validateImg(file) {
      return new Promise((resolve, reject) => {
        if (this.width && this.height) {
          let img = new Image();
          img.src = window.URL.createObjectURL(file);
          img.onload = () => {
            if (
              img.width === parseInt(this.width) &&
              img.height === parseInt(this.height)
            ) {
              resolve(true);
            } else {
              resolve(false);
            }
          };
        } else {
          resolve(true);
        }
      });
    },
    handleChanges(fieldName, fileList) {
      this.reset();
      if (!fileList.length) return;
      this.status = LOADING;

      [].slice.call(fileList).map((file) => {
        var url = URL.createObjectURL(file);
        this.validateImg(file).then((valid) => {
          if (valid) {
            if (!this.uploadFileReturnFileName) {
              this.$emit("ImageDataChanged", file);
              this.$emit("ImageURLDataChanged", url);
            }
            this.status = SUCCESS;
            this.output.push({
              name: this.sanitizeFileName(file.name),
              content: url,
            });

            this.status = LOADING;
            (this.isLoading = true),
              this.fileToBase64(file)
                .then(this.resolveAfterTime(1500))
                .then((res) => {
                  var data = {
                    base64: res,
                    isDocument: this.pdf,
                    fileName: file.name,
                    extension: file.name.split(".").pop(),
                  };

                  var payload = {
                    data: data,
                    success: (response) => {
                      this.$emit("input", response.data);

                      this.status = SUCCESS;
                      this.editingImage = false;
                      this.isLoading = false;
                    },
                    error: (error) => {
                      this.status = FAILED;
                      this.isLoading = false;
                    },
                  };
                  this.$store.dispatch("uploadFile", payload);
                });
          } else {
            this.status = INITIAL;

            this.error = `The image size must be ${this.width}px by ${this.height}px`;
          }
        });
      });
    },
    sanitizeFileName(name) {
      return name
        .replace(/\.[^/.]+$/, "")
        .replace(/[^a-z0-9]/gi, "_")
        .toLowerCase();
    },
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let res = reader.result;
          resolve(res);
        };
        reader.onerror = (error) => reject(error);
      });
    },
    reset() {
      this.status = INITIAL;
      this.errors = null;
      this.output = [];
      this.error = null;
    },
    resolveAfterTime(time) {
      return (x) => {
        return new Promise((resolve) => setTimeout(() => resolve(x), time));
      };
    },
    imageUploaded: function (file) {
      this.$emit("ImageUploaded", file);
      this.$emit("input", file);
      this.editingImage = false;
    },
    imageError(message) {
      this.error = message;
    },
    editImage: function () {
      this.editingImage = !this.editingImage;
    },
    updateImage: function () {
      this.editingImage = !this.editingImage;
    },
    removeImage: function () {
      
      this.imageUploaded(null);
    },

    isImage() {
      if (this.value && this.value.toLowerCase().lastIndexOf(".pdf") === -1) {
        return true;
      } else {
        return false;
      }
    },
    getImageSource() {
      
      if (this.imageFileName) {
        return this.$store.state.cdnUrl + (this.pdf == true?'documents/':'') +  this.imageFileName;
      }

      if (this.value) {
        return this.$store.state.cdnUrl + (this.pdf==true?'documents/':'') + this.value;
      }

      return "";
    },
  },
};
</script> 
<style scoped lang="scss">
.error {
  color: tomato;
}
.wrapper {
  border: 1px solid #efefef;
  border-radius: 0.25rem;
  padding: 10px;
  margin: 5px;
  position: relative;
  min-height: 44px;
}
.pdf {
  position: absolute;
  top: 10px;
  right: 80px;
  cursor: pointer;
  font-size: 25px;
  color: black;
}
.trash {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 25px;
}
.folder {
  position: absolute;
  top: 10px;
  right: 40px;
  cursor: pointer;
  font-size: 25px;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 90px;

  &.dark {
    background-color: gray;
  }
}
.previewImage {
  max-height: 100px;
  margin: auto;
}
.not-image {
  align-self: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 257px;
}
.input-file {
  display: none;
}
</style> 
