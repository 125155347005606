import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { EventBus } from "@/shared/EventBus";

Vue.use(Vuex);

var baseUrl = "https://api.hyundai.co.za/api/";
var cdnUrl = "https://cdn.hyundai.co.za/";
var onlineUrl = "https://c2bapi.hyundai.co.za/";


if (location.href.indexOf("localhost") > -1) {

  baseUrl = "https://api.hyundai.co.za/api/";
  cdnUrl = "https://api.hyundai.co.za/ImageStorage/";
}

if (location.href.indexOf("localhost") !== -1) {
   onlineUrl = "http://c2bapistage.hyundai.co.za/";
   //baseUrl = "http://localhost:57626/api/";
  // cdnUrl = "http://192.168.8.101:81/ImageStorage/";
  baseUrl = "https://api.stage.hyundai.co.za/api/";
  cdnUrl = "https://api.stage.hyundai.co.za/ImageStorage/";
} else if (location.href.indexOf("stagecms.hyundai.co.za") !== -1) {
  baseUrl = "https://stageapi.hyundai.co.za/api/";
  cdnUrl = "https://stageapi.hyundai.co.za/ImageStorage/";
} else if (location.href.indexOf("oemcms.stage.hyundai.co.za") !== -1) {
  baseUrl = "https://api.stage.hyundai.co.za/api/";
  cdnUrl = "https://api.stage.hyundai.co.za/ImageStorage/";
} else {
  baseUrl = "https://api.hyundai.co.za/api/";
  cdnUrl = "https://cdn.hyundai.co.za/";
}
var getHeader = function (state) {
  if (state && state.loginData) {
    var token = state.loginData.token;
    return "Bearer " + token;
  } else {
    router.push({
      path: "login"
    });
  }
};
var handleErrorResponse = function (response, error) {
  if (response && response.response) {
    if (response.response.statusText == "Unauthorized") {
      router.push({
        path: "login"
      });
    } else {
      error(response);
    }
  } else {
    if (response.Message) {
      error(response.Message);
    } else {
      error(response);
    }
  }
};
export const store = new Vuex.Store({
  state: {
    idToken: null,
    userId: null,
    user: null,
    Users: [],
    PasswordResetTokens: [],
    FloorVehicleAttributeStores: [],
    CommercialVehicleCategories: [],
    ContentBlocks: [],
    VehicleCategories: [],
    VehicleModels: [],
    Vehicles: [],
    SecondHandVehicles: [],
    HomePageLinks: [],
    BannerLayouts: [],
    Dealers: [],
    PromotionBanners: [],
    VehicleContentBlocks: [],
    VehiclePromotionBanners: [],
    VehicleVideos: [],
    VehicleVariants: [],
    FleetSections: [],
    Enquiries: [],
    PromotionContentBlocks: [],
    DealerTeamMembers: [],
    ServiceBookings: [],
    VehicleThreeSixtyImages: [],
    TestDriveBookings: [],
    Promotions: [],
    OwnerServicesBanners: [],
    SuggestedProducts: [],
    GlobalSettings: [],
    BrochureUpload: [],
    FleetBanners: [],
    PreOwnedBanners: [],
    Accessories: [],
    DealerPromotions: [],
    PageSeos: [],
    DealershipUsers: [],
    VehicleGalleryImages: [],
    baseUrl: baseUrl,
    cdnUrl: cdnUrl
  },
  getters: {
    UsersGetter: state => {
      return state.Users;
    },
    PasswordResetTokensGetter: state => {
      return state.PasswordResetTokens;
    },
    FloorVehicleAttributeStoresGetter: state => {
      return state.FloorVehicleAttributeStores;
    },
    CommercialVehicleCategoriesGetter: state => {
      return state.CommercialVehicleCategories;
    },
    ContentBlocksGetter: state => {
      return state.ContentBlocks;
    },
    VehicleCategoriesGetter: state => {
      return state.VehicleCategories;
    },
    VehicleModelsGetter: state => {
      return state.VehicleModels;
    },
    VehiclesGetter: state => {
      return state.Vehicles;
    },
    SecondHandVehiclesGetter: state => {
      return state.SecondHandVehicles;
    },
    HomePageLinksGetter: state => {
      return state.HomePageLinks;
    },
    BannerLayoutsGetter: state => {
      return state.BannerLayouts;
    },
    DealersGetter: state => {
      return state.Dealers;
    },
    PromotionBannersGetter: state => {
      return state.PromotionBanners;
    },
    VehicleContentBlocksGetter: state => {
      return state.VehicleContentBlocks;
    },
    VehiclePromotionBannersGetter: state => {
      return state.VehiclePromotionBanners;
    },
    VehicleVideosGetter: state => {
      return state.VehicleVideos;
    },
    VehicleVariantsGetter: state => {
      return state.VehicleVariants;
    },
    FleetSectionsGetter: state => {
      return state.FleetSections;
    },
    EnquiriesGetter: state => {
      return state.Enquiries;
    },
    PromotionContentBlocksGetter: state => {
      return state.PromotionContentBlocks;
    },
    DealerTeamMembersGetter: state => {
      return state.DealerTeamMembers;
    },
    ServiceBookingsGetter: state => {
      return state.ServiceBookings;
    },
    VehicleThreeSixtyImagesGetter: state => {
      return state.VehicleThreeSixtyImages;
    },
    TestDriveBookingsGetter: state => {
      return state.TestDriveBookings;
    },
    PromotionsGetter: state => {
      return state.Promotions;
    },
    OwnerServicesBannersGetter: state => {
      return state.OwnerServicesBanners;
    },
    SuggestedProductsGetter: state => {
      return state.SuggestedProducts;
    },
    GlobalSettingsGetter: state => {
      return state.GlobalSettings;
    },
    BrochureUploadGetter: state => {
      return state.BrochureUpload;
    },
    FleetBannersGetter: state => {
      return state.FleetBanners;
    },
    PreOwnedBannersGetter: state => {
      return state.PreOwnedBanners;
    },
    AccessoriesGetter: state => {
      return state.Accessories;
    },
    DealerPromotionsGetter: state => {
      return state.DealerPromotions;
    },
    PageSeosGetter: state => {
      return state.PageSeos;
    },
    DealershipUsersGetter: state => {
      return state.DealershipUsers;
    },
    VehicleGalleryImagesGetter: state => {
      return state.VehicleGalleryImages;
    }
  },
  mutations: {
    setUsers: (state, payload) => {
      Vue.set(state, "Users", payload.data);
    },
    setPasswordResetTokens: (state, payload) => {
      Vue.set(state, "PasswordResetTokens", payload.data);
    },
    setFloorVehicleAttributeStores: (state, payload) => {
      Vue.set(state, "FloorVehicleAttributeStores", payload.data);
    },
    setCommercialVehicleCategories: (state, payload) => {
      Vue.set(state, "CommercialVehicleCategories", payload.data);
    },
    setContentBlocks: (state, payload) => {
      Vue.set(state, "ContentBlocks", payload.data);
    },
    setVehicleCategories: (state, payload) => {
      Vue.set(state, "VehicleCategories", payload.data);
    },
    setVehicleModels: (state, payload) => {
      Vue.set(state, "VehicleModels", payload.data);
    },
    setVehicles: (state, payload) => {
      Vue.set(state, "Vehicles", payload.data);
    },
    setSecondHandVehicles: (state, payload) => {
      Vue.set(state, "SecondHandVehicles", payload.data);
    },
    setHomePageLinks: (state, payload) => {
      Vue.set(state, "HomePageLinks", payload.data);
    },
    setBannerLayouts: (state, payload) => {
      Vue.set(state, "BannerLayouts", payload.data);
    },
    setDealers: (state, payload) => {
      Vue.set(state, "Dealers", payload.data);
    },
    setPromotionBanners: (state, payload) => {
      Vue.set(state, "PromotionBanners", payload.data);
    },
    setVehicleContentBlocks: (state, payload) => {
      Vue.set(state, "VehicleContentBlocks", payload.data);
    },
    setVehiclePromotionBanners: (state, payload) => {
      Vue.set(state, "VehiclePromotionBanners", payload.data);
    },
    setVehicleVideos: (state, payload) => {
      Vue.set(state, "VehicleVideos", payload.data);
    },
    setVehicleVariants: (state, payload) => {
      Vue.set(state, "VehicleVariants", payload.data);
    },
    setFleetSections: (state, payload) => {
      Vue.set(state, "FleetSections", payload.data);
    },
    setEnquiries: (state, payload) => {
      Vue.set(state, "Enquiries", payload.data);
    },
    setPromotionContentBlocks: (state, payload) => {
      Vue.set(state, "PromotionContentBlocks", payload.data);
    },
    setDealerTeamMembers: (state, payload) => {
      Vue.set(state, "DealerTeamMembers", payload.data);
    },
    setServiceBookings: (state, payload) => {
      Vue.set(state, "ServiceBookings", payload.data);
    },
    setVehicleThreeSixtyImages: (state, payload) => {
      Vue.set(state, "VehicleThreeSixtyImages", payload.data);
    },
    setTestDriveBookings: (state, payload) => {
      Vue.set(state, "TestDriveBookings", payload.data);
    },
    setPromotions: (state, payload) => {
      Vue.set(state, "Promotions", payload.data);
    },
    setOwnerServicesBanners: (state, payload) => {
      Vue.set(state, "OwnerServicesBanners", payload.data);
    },
    setSuggestedProducts: (state, payload) => {
      Vue.set(state, "SuggestedProducts", payload.data);
    },
    setGlobalSettings: (state, payload) => {
      Vue.set(state, "GlobalSettings", payload.data);
    },
    setBrochureUpload: (state, payload) => {
      Vue.set(state, "BrochureUpload", payload.data);
    },
    setFleetBanners: (state, payload) => {
      Vue.set(state, "FleetBanners", payload.data);
    },
    setPreOwnedBanners: (state, payload) => {
      Vue.set(state, "PreOwnedBanners", payload.data);
    },
    setAccessories: (state, payload) => {
      Vue.set(state, "Accessories", payload.data);
    },
    setDealerPromotions: (state, payload) => {
      Vue.set(state, "DealerPromotions", payload.data);
    },
    setPageSeos: (state, payload) => {
      Vue.set(state, "PageSeos", payload.data);
    },
    setDealershipUsers: (state, payload) => {
      Vue.set(state, "DealershipUsers", payload.data);
    },
    setVehicleGalleryImages: (state, payload) => {
      Vue.set(state, "VehicleGalleryImages", payload.data);
    },
    storeUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    login: (context, payload) => {
      axios
        .post(baseUrl + "token", payload.data)
        .then(response => {
          EventBus.$emit("loginSuccess", response);
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //USERS
    getUsers: (context, payload) => {
      axios
        .get(baseUrl + "Users", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addUsers: (context, payload) => {
      axios
        .post(baseUrl + "Users", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    uploadFile: (context, payload) => {

      axios
        .post(baseUrl + "FileUpload" + "/UploadBase64File", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editUsers: (context, payload) => {
      axios
        .post(baseUrl + "Users" + "/Update", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteUsers: (context, payload) => {
      axios
        .post(baseUrl + "Users" + "/Delete/" + payload.data.Id, payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //PASSWORDRESETTOKENS
    getPasswordResetTokens: (context, payload) => {
      axios
        .get(baseUrl + "PasswordResetTokens", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addPasswordResetTokens: (context, payload) => {
      axios
        .post(baseUrl + "PasswordResetTokens", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editPasswordResetTokens: (context, payload) => {
      axios
        .post(baseUrl + "PasswordResetTokens" + "/Update", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deletePasswordResetTokens: (context, payload) => {
      axios
        .post(
          baseUrl + "PasswordResetTokens" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //FLOORVEHICLEATTRIBUTESTORES
    getFloorVehicleAttributeStores: (context, payload) => {
      axios
        .get(baseUrl + "FloorVehicleAttributeStores", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addFloorVehicleAttributeStores: (context, payload) => {
      axios
        .post(baseUrl + "FloorVehicleAttributeStores", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editFloorVehicleAttributeStores: (context, payload) => {
      axios
        .post(
          baseUrl + "FloorVehicleAttributeStores" + "/Update",
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteFloorVehicleAttributeStores: (context, payload) => {
      axios
        .post(
          baseUrl +
          "FloorVehicleAttributeStores" +
          "/Delete/" +
          payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //COMMERCIALVEHICLECATEGORIES
    getCommercialVehicleCategories: (context, payload) => {
      axios
        .get(baseUrl + "CommercialVehicleCategories", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addCommercialVehicleCategories: (context, payload) => {
      axios
        .post(baseUrl + "CommercialVehicleCategories", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editCommercialVehicleCategories: (context, payload) => {
      axios
        .post(
          baseUrl + "CommercialVehicleCategories" + "/Update",
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteCommercialVehicleCategories: (context, payload) => {
      axios
        .post(
          baseUrl +
          "CommercialVehicleCategories" +
          "/Delete/" +
          payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //CONTENTBLOCKS
    getContentBlocks: (context, payload) => {
      axios
        .get(baseUrl + "ContentBlocks", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addContentBlocks: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "ContentBlocks" + "/Add", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editContentBlocks: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "ContentBlocks" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteContentBlocks: (context, payload) => {
      axios
        .post(
          baseUrl + "ContentBlocks" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //VEHICLECATEGORIES
    getVehicleCategories: (context, payload) => {
      axios
        .get(baseUrl + "VehicleCategories", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addVehicleCategories: (context, payload) => {
      axios
        .post(baseUrl + "VehicleCategories", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editVehicleCategories: (context, payload) => {
      axios
        .post(baseUrl + "VehicleCategories" + "/Update", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteVehicleCategories: (context, payload) => {
      axios
        .post(
          baseUrl + "VehicleCategories" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //VEHICLEMODELS
    getVehicleModels: (context, payload) => {
      axios
        .get(baseUrl + "VehicleModels", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addVehicleModels: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "VehicleModels", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editVehicleModels: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.files.length > 0) {
        _.forEach(payload.files, function (item, index) {
          formData.append("file", item);
        });
      }
      axios
        .post(baseUrl + "VehicleModels" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteVehicleModels: (context, payload) => {
      axios
        .post(
          baseUrl + "VehicleModels" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //VEHICLES
    getVehicles: (context, payload) => {
      axios
        .get(baseUrl + "Vehicles", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addVehicles: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "Vehicles", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editVehicles: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "Vehicles" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteVehicles: (context, payload) => {
      axios
        .post(
          baseUrl + "Vehicles" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //SECONDHANDVEHICLES
    getSecondHandVehicles: (context, payload) => {
      axios
        .get(baseUrl + "SecondHandVehicles", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addSecondHandVehicles: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "SecondHandVehicles", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editSecondHandVehicles: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "SecondHandVehicles" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteSecondHandVehicles: (context, payload) => {
      axios
        .post(
          baseUrl + "SecondHandVehicles" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //HOMEPAGELINKS
    getHomePageLinks: (context, payload) => {
      axios
        .get(baseUrl + "HomePageLinks", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addHomePageLinks: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "HomePageLinks", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editHomePageLinks: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "HomePageLinks" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteHomePageLinks: (context, payload) => {
      axios
        .post(
          baseUrl + "HomePageLinks" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //BANNERLAYOUTS
    getBannerLayouts: (context, payload) => {
      axios
        .get(baseUrl + "BannerLayouts", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addBannerLayouts: (context, payload) => {
      axios
        .post(baseUrl + "BannerLayouts", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editBannerLayouts: (context, payload) => {
      axios
        .post(baseUrl + "BannerLayouts" + "/Update", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteBannerLayouts: (context, payload) => {
      axios
        .post(
          baseUrl + "BannerLayouts" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //DEALERS
    getDealers: (context, payload) => {
      axios
        .get(baseUrl + "Dealers", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },

    addDealers: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "Dealers", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editDealers: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "Dealers" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteDealers: (context, payload) => {
      axios
        .post(
          baseUrl + "Dealers" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    getAllDealerEvents: (context,payload) => {
      axios
        .get(baseUrl + "DealerEvents", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse => {
          handleErrorResponse(errorResponse, context.commit); // you may pass a commit function to handle specific errors
        });
    },
    addDealerEvents: (context, payload) => {
   
   
      axios
        .post(baseUrl + "DealerEvents", payload.data, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editDealerEvents: (context, payload) => {
      
   
      // The controller expects PUT request for updates
      axios
        .put(baseUrl + "DealerEvents/" + payload.data.EventId, payload.data, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteDealerEvents: (context, payload) => {
      // The controller expects DELETE request for deletions
      axios
        .delete(baseUrl + "DealerEvents/" + payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    // Assuming you want to add a new action for fetching the RSVP report
    getDealerEventRSVPReport: (context, payload) => {

      
      axios.get(baseUrl + "DealerEventRSVP/Download/" + payload.data.eventId, {
        headers: {
          Authorization: getHeader(context.state)
        },
        responseType: 'blob',  // This ensures the response is treated as a Blob
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'download.xlsx';
        if (contentDisposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) { 
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);  // Clean up after download
        if (payload.success) {
          payload.success(response);
        }
      })
      .catch(errorResponse => {
        if (payload.error) {
          handleErrorResponse(errorResponse, payload.error);
        }
      });
    },
    

    //PROMOTIONBANNERS
    getPromotionBanners: (context, payload) => {
      axios
        .get(baseUrl + "PromotionBanners", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    getPromotionBannersPromotions: (context, payload) => {
      axios
        .get(baseUrl + "PromotionBanners/Promotion", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addPromotionBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "PromotionBanners", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editPromotionBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "PromotionBanners" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deletePromotionBanners: (context, payload) => {
      axios
        .post(
          baseUrl + "PromotionBanners" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },

    //NEWSITEMSPROMOTIONBANNERS
    getNewsFeedPromotionBanners: (context, payload) => {
      axios
        .get(baseUrl + "NewsFeedPromotionBanners", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addNewsFeedPromotionBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "NewsFeedPromotionBanners", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editNewsFeedPromotionBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "NewsFeedPromotionBanners" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteNewsFeedPromotionBanners: (context, payload) => {
      axios
        .post(
          baseUrl + "NewsFeedPromotionBanners" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },

    //CORPORATESALESPROMOTIONBANNERS
    getCorporateSalesPromotionBanners: (context, payload) => {
      axios
        .get(baseUrl + "CorporateSalesPromotionBanners", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addCorporateSalesPromotionBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "CorporateSalesPromotionBanners", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editCorporateSalesPromotionBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(
          baseUrl + "CorporateSalesPromotionBanners" + "/Update",
          formData,
          {
            headers: {
              Authorization: getHeader(context.state),
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteCorporateSalesPromotionBanners: (context, payload) => {
      axios
        .post(
          baseUrl +
          "CorporateSalesPromotionBanners" +
          "/Delete/" +
          payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },

    //VEHICLECONTENTBLOCKS
    getVehicleContentBlocks: (context, payload) => {
      axios
        .get(baseUrl + "VehicleContentBlocks", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addVehicleContentBlocks: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "VehicleContentBlocks", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editVehicleContentBlocks: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "VehicleContentBlocks" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteVehicleContentBlocks: (context, payload) => {
      axios
        .post(
          baseUrl + "VehicleContentBlocks" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //VEHICLEPROMOTIONBANNERS
    getVehiclePromotionBanners: (context, payload) => {
      axios
        .get(baseUrl + "VehiclePromotionBanners", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addVehiclePromotionBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "VehiclePromotionBanners", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editVehiclePromotionBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "VehiclePromotionBanners" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteVehiclePromotionBanners: (context, payload) => {
      axios
        .post(
          baseUrl + "VehiclePromotionBanners" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //VEHICLEVIDEOS
    getVehicleVideos: (context, payload) => {
      axios
        .get(baseUrl + "VehicleVideos", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addVehicleVideos: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "VehicleVideos", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editVehicleVideos: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "VehicleVideos" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteVehicleVideos: (context, payload) => {
      axios
        .post(
          baseUrl + "VehicleVideos" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //VEHICLEVARIANTS
    getVehicleVariants: (context, payload) => {
      axios
        .get(baseUrl + "VehicleVariants", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addVehicleVariants: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "VehicleVariants", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );

      // axios.post(baseUrl + 'VehicleVariants', payload.data, {
      //   headers: {
      //     'Authorization': getHeader(context.state)
      //   }
      // }).then((response) => {
      //   payload.success(response)
      // }).catch(errorResponse => handleErrorResponse(errorResponse, payload.error));
    },
    editVehicleVariants: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "VehicleVariants" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );

      // axios.post(baseUrl + 'VehicleVariants' + '/Update', payload.data, {
      //   headers: {
      //     'Authorization': getHeader(context.state)
      //   }
      // }).then((response) => {
      //   payload.success(response)
      // }).catch(errorResponse => handleErrorResponse(errorResponse, payload.error));
    },
    deleteVehicleVariants: (context, payload) => {
      axios
        .post(
          baseUrl + "VehicleVariants" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //FLEETSECTIONS
    getFleetSections: (context, payload) => {
      axios
        .get(baseUrl + "FleetSections", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addFleetSections: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "FleetSections", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editFleetSections: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "FleetSections" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteFleetSections: (context, payload) => {
      axios
        .post(
          baseUrl + "FleetSections" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //ENQUIRIES
    getEnquiries: (context, payload) => {
      axios
        .get(baseUrl + "Enquiries", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addEnquiries: (context, payload) => {
      axios
        .post(baseUrl + "Enquiries", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editEnquiries: (context, payload) => {
      axios
        .post(baseUrl + "Enquiries" + "/Update", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteEnquiries: (context, payload) => {
      axios
        .post(
          baseUrl + "Enquiries" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //PROMOTIONCONTENTBLOCKS
    getPromotionContentBlocks: (context, payload) => {
      axios
        .get(baseUrl + "PromotionContentBlocks", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addPromotionContentBlocks: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "PromotionContentBlocks", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editPromotionContentBlocks: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "PromotionContentBlocks" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deletePromotionContentBlocks: (context, payload) => {
      axios
        .post(
          baseUrl + "PromotionContentBlocks" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //DEALERTEAMMEMBERS
    getDealerTeamMembers: (context, payload) => {
      axios
        .get(baseUrl + "DealerTeamMembers", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addDealerTeamMembers: (context, payload) => {
      axios
        .post(baseUrl + "DealerTeamMembers", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editDealerTeamMembers: (context, payload) => {
      axios
        .post(baseUrl + "DealerTeamMembers" + "/Update", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteDealerTeamMembers: (context, payload) => {
      axios
        .post(
          baseUrl + "DealerTeamMembers" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //SERVICEBOOKINGS
    getServiceBookings: (context, payload) => {
      axios
        .get(baseUrl + "ServiceBookings", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addServiceBookings: (context, payload) => {
      axios
        .post(baseUrl + "ServiceBookings", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editServiceBookings: (context, payload) => {
      axios
        .post(baseUrl + "ServiceBookings" + "/Update", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteServiceBookings: (context, payload) => {
      axios
        .post(
          baseUrl + "ServiceBookings" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },

    //Searches

    getSearchIndexes: (context, payload) => {
      axios
        .get(baseUrl + "Search/GetSearches", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    doSearch: (context, payload) => {
      axios
        .get(baseUrl + `Search/DoSearch/${payload.data.search}/${payload.data.page}`, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addSearchIndex: (context, payload) => {

      axios
        .post(baseUrl + "Search/Create", payload.data, {
          headers: {
            Authorization: getHeader(context.state),
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    updateSearchIndexCache: (context, payload) => {

      axios
        .post(baseUrl + "Search/UpdateCache", null, {
          headers: {
            Authorization: getHeader(context.state),
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editSearchIndex: (context, payload) => {

      axios
        .post(baseUrl + "Search/Update", payload.data, {
          headers: {
            Authorization: getHeader(context.state),
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteSearchIndex: (context, payload) => {
      axios
        .post(
          baseUrl + "Search" + "/Delete/" + payload.data.Id,
          null,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //VEHICLETHREESIXTYIMAGES
    getVehicleThreeSixtyImages: (context, payload) => {
      axios
        .get(baseUrl + "VehicleThreeSixtyImages", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addVehicleThreeSixtyImages: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "VehicleThreeSixtyImages", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editVehicleThreeSixtyImages: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "VehicleThreeSixtyImages" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteVehicleThreeSixtyImages: (context, payload) => {
      axios
        .post(
          baseUrl + "VehicleThreeSixtyImages" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //TESTDRIVEBOOKINGS
    getTestDriveBookings: (context, payload) => {
      axios
        .get(baseUrl + "TestDriveBookings", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addTestDriveBookings: (context, payload) => {
      axios
        .post(baseUrl + "TestDriveBookings", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editTestDriveBookings: (context, payload) => {
      axios
        .post(baseUrl + "TestDriveBookings" + "/Update", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteTestDriveBookings: (context, payload) => {
      axios
        .post(
          baseUrl + "TestDriveBookings" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //PROMOTIONS
    getPromotions: (context, payload) => {
      axios
        .get(baseUrl + "Promotions", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addPromotions: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.mainImage) {
        formData.append("mainImage", payload.mainImage);
      }
      if (payload.homeImage) {
        formData.append("homeImage", payload.homeImage);
      }
      axios
        .post(baseUrl + "Promotions", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editPromotions: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.mainImage) {
        formData.append("mainImage", payload.mainImage);
      }
      if (payload.homeImage) {
        formData.append("homeImage", payload.homeImage);
      }
      axios
        .post(baseUrl + "Promotions" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deletePromotions: (context, payload) => {
      axios
        .post(
          baseUrl + "Promotions" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //OTHER Services
    getOtherBanners: (context, payload) => {
      axios
        .get(baseUrl + "OtherBanners", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addOtherBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "OtherBanners", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    getOtherBannerTypes: (context, payload) => {

      axios
        .get(baseUrl + "OtherBanners/Types", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editOtherBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "OtherBanners" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteOtherBanners: (context, payload) => {
      axios
        .post(
          baseUrl + "OtherBanners" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //OWNERSERVICESBANNERS
    getOwnerServicesBanners: (context, payload) => {
      axios
        .get(baseUrl + "OwnerServicesBanners", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addOwnerServicesBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "OwnerServicesBanners", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editOwnerServicesBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "OwnerServicesBanners" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteOwnerServicesBanners: (context, payload) => {
      axios
        .post(
          baseUrl + "OwnerServicesBanners" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //SUGGESTEDPRODUCTS
    getSuggestedProducts: (context, payload) => {
      axios
        .get(baseUrl + "SuggestedProducts", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addSuggestedProducts: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "SuggestedProducts", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editSuggestedProducts: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "SuggestedProducts" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteSuggestedProducts: (context, payload) => {
      axios
        .post(
          baseUrl + "SuggestedProducts" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //GLOBALSETTINGS
    getGlobalSettings: (context, payload) => {
      axios
        .get(baseUrl + "GlobalSettings", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addGlobalSettings: (context, payload) => {
      axios
        .post(baseUrl + "GlobalSettings", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editGlobalSettings: (context, payload) => {
      axios
        .post(baseUrl + "GlobalSettings" + "/Update", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteGlobalSettings: (context, payload) => {
      axios
        .post(
          baseUrl + "GlobalSettings" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //BROCHUREUPLOAD
    getBrochureUpload: (context, payload) => {
      axios
        .get(baseUrl + "BrochureUpload", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addBrochureUpload: (context, payload) => {
      axios
        .post(baseUrl + "BrochureUpload", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editBrochureUpload: (context, payload) => {
      axios
        .post(baseUrl + "BrochureUpload" + "/Update", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteBrochureUpload: (context, payload) => {
      axios
        .post(
          baseUrl + "BrochureUpload" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //FLEETBANNERS
    getFleetBanners: (context, payload) => {
      axios
        .get(baseUrl + "FleetBanners", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addFleetBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "FleetBanners", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editFleetBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "FleetBanners" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteFleetBanners: (context, payload) => {
      axios
        .post(
          baseUrl + "FleetBanners" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //PREOWNEDBANNERS
    getPreOwnedBanners: (context, payload) => {
      axios
        .get(baseUrl + "PreOwnedBanners", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addPreOwnedBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "PreOwnedBanners", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editPreOwnedBanners: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "PreOwnedBanners" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deletePreOwnedBanners: (context, payload) => {
      axios
        .post(
          baseUrl + "PreOwnedBanners" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //ACCESSORIES
    getAccessories: (context, payload) => {
      axios
        .get(baseUrl + "Accessories", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addAccessories: (context, payload) => {
      axios
        .post(baseUrl + "Accessories", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editAccessories: (context, payload) => {
      axios
        .post(baseUrl + "Accessories" + "/Update", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteAccessories: (context, payload) => {
      axios
        .post(
          baseUrl + "Accessories" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //DEALERPROMOTIONS
    getDealerPromotions: (context, payload) => {
      axios
        .get(baseUrl + "DealerPromotions", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addDealerPromotions: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "DealerPromotions", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editDealerPromotions: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "DealerPromotions" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteDealerPromotions: (context, payload) => {
      axios
        .post(
          baseUrl + "DealerPromotions" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //PAGESEOS
    getPageSeos: (context, payload) => {
      axios
        .get(baseUrl + "PageSeos", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addPageSeos: (context, payload) => {
      axios
        .post(baseUrl + "PageSeos", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editPageSeos: (context, payload) => {
      axios
        .post(baseUrl + "PageSeos" + "/Update", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deletePageSeos: (context, payload) => {
      axios
        .post(
          baseUrl + "PageSeos" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //DEALERSHIPUSERS
    getDealershipUsers: (context, payload) => {
      axios
        .get(baseUrl + "DealershipUsers", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addDealershipUsers: (context, payload) => {
      axios
        .post(baseUrl + "DealershipUsers", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editDealershipUsers: (context, payload) => {
      axios
        .post(baseUrl + "DealershipUsers" + "/Update", payload.data, {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteDealershipUsers: (context, payload) => {
      axios
        .post(
          baseUrl + "DealershipUsers" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //VEHICLEGALLERYIMAGES
    getVehicleGalleryImages: (context, payload) => {
      axios
        .get(baseUrl + "VehicleGalleryImages", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addVehicleGalleryImages: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "VehicleGalleryImages", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editVehicleGalleryImages: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "VehicleGalleryImages" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteVehicleGalleryImages: (context, payload) => {
      axios
        .post(
          baseUrl + "VehicleGalleryImages" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //NEWSFEEDITEMS
    getNewsFeedItems: (context, payload) => {
      axios
        .get(baseUrl + "NewsFeedItems", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addNewsFeedItem: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "NewsFeedItems", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editNewsFeedItem: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "NewsFeedItems" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteNewsFeedItem: (context, payload) => {
      axios
        .post(
          baseUrl + "NewsFeedItems" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    //NEWSFEEDGALLERYITEMS
    getNewsFeedGalleryItems: (context, payload) => {
      axios
        .get(baseUrl + "NewsFeedGalleryItems", {
          headers: {
            Authorization: getHeader(context.state)
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    addNewsFeedGalleryItems: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "NewsFeedGalleryItems", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    editNewsFeedGalleryItems: (context, payload) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(payload.data));
      if (payload.image) {
        formData.append("file", payload.image);
      }
      axios
        .post(baseUrl + "NewsFeedGalleryItems" + "/Update", formData, {
          headers: {
            Authorization: getHeader(context.state),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    deleteNewsFeedGalleryItems: (context, payload) => {
      axios
        .post(
          baseUrl + "NewsFeedGalleryItems" + "/Delete/" + payload.data.Id,
          payload.data,
          {
            headers: {
              Authorization: getHeader(context.state)
            }
          }
        )
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
    getC2BModels: (context, payload) => {
      axios
        .get(onlineUrl + "api/vehicle-models/full", {

        })
        .then(response => {
          payload.success(response);
        })
        .catch(errorResponse =>
          handleErrorResponse(errorResponse, payload.error)
        );
    },
  }
});
