export const utils = {
  random: function(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  },

  shuffleArray: function(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1))
      let temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  },
  isNull: function(obj) {
    if (typeof obj === 'undefined' || obj === null || obj === 'null') {
      return true;
    }
    return false;
  },
  isNullOrEmpty: function(obj) {
    if(this.isNull(obj))
    {
      return true
    }
    else
    {
      if(obj.length <= 0)
      {
        return true;
      }
      return false;
    }
  },
};