<template>
  <div>
    <select
      style="display: block !important"
      class="form-control"
      @change="valueChanged()"
      v-model="dataValue"
    >
      <option v-for="option in vehicles" :key="option.Id" :value="option.Id">
        {{ option.Description }}
      </option>
    </select>
  </div>
</template>
<script>

export default {
  name: "DealerSelect",

  data() {
    return {  
        vehicles: [],
        dataValue: null,   
    };
  },
  created() {
    this.getDealers();
  
  },

  props: ["value"],

  methods: {
    valueChanged() {
      this.$emit("input", this.dataValue);
    },

    getDealers() {
      var payload = {
        success: (response) => {
          
          this.vehicles = response.data.sort((a, b) => (a.Description > b.Description ? 1 : -1));
          this.dataValue = this.value;
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getDealers", payload);
    },
  },
};
</script> 
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
