
    <template>
  <div class="spinner-wrapper" :class="{ overlay: overlay }">
    <svg class="spinner" :class="{ overlay: overlay }" viewBox="0 0 50 50">
      <circle
        class="path"
        cx="25"
        cy="25"
        r="10"
        fill="none"
        stroke-width="2"
      ></circle>
    </svg>
  </div>
</template>



<script>
export default {
  name: "Spinner",
  props: ["overlay"],
};
</script>


<style scoped lang="scss">
.spinner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.overlay {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.56);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999999999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    margin: -25px 0 0 -25px;
    width: 100px;
    height: 100px;

    & .path {
      stroke: #00aad2;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
}
</style>
