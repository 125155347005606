<template>
  <div>
    <div class="heading">
      <div class="input-group">
        <div class="input-group">
          <input
            type="text"
            @keyup="search"
            class="form-control rounded"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="search-addon"
          />
        </div>
      </div>
    </div>
    <div class="data-wrap">
      <div class="data-block card" v-for="data in filtered" :key="data.Id">
        <div class="row-item" v-for="field in fields" :key="field">
          <div v-if="field !== 'Image'" class="listValue">
            {{ data[field] }}
          </div>
        </div>
        <div class="img-row" v-if="showImage">
          <img :src="getImageSource(data)" alt="" />
        </div>

        <button @click="selectItem(data)" type="button" class="btn btn-info">
          Edit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataBlocks",

  props: {
    // Basic type check
    //  (`null` and `undefined` values will allow any type)
    data: Array,
    showImage: {
      type: Boolean,
      value: false,
      required: false,
    },
    // Multiple possible types
    fields: [Array],
    // Required string
    imageField: {
      type: String,
      required: false,
    },
  },
  data() {
    return { filtered: {} };
  },
  created: function () {},
  computed: {},
  watch: {
    data: function (newData) {
      this.filtered = newData;
    },
  },
  methods: {
    getImageSource(data) {
      var imgField = "Image";
      if (this.imageField) {
        imgField = this.imageField;
      }
      if (!this.isNull(data) && !this.isNull(data[imgField])) {
        if (data[imgField]) {
          return this.$store.state.cdnUrl + data[imgField];
        } else {
          return data[imgField];
        }
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    search(item) {
      var searchValue = item.srcElement.value.toLowerCase();

      if (searchValue) {
        this.filtered = this.data.filter((g) =>
          g[this.fields[0]]
            ? g[this.fields[0]].toLowerCase().lastIndexOf(searchValue) > -1
            : null
        );
      } else {
        this.filtered = this.data;
      }
    },
    selectItem(item) {
      this.$emit("itemSelected", item);
    },
  },
};
</script>

<style scoped lang="scss">
.heading {
  padding-left: 10px;

  .input-group {
    width: 230px;
  }
}
.data-wrap {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  .data-block {
    width: fit-content;
    margin: 10px;
    padding: 10px;
    min-width: 160px;
    .img-row {
      width: 100%;

      img {
        height: 100px;
        margin: auto;
      }
    }

    .row-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      .listField {
        font-weight: bold;
        padding-left: 10px;
      }
    }
  }
}
</style>
