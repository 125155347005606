import Vue from 'vue'
import Router from 'vue-router'
import {
  store
} from '@/store/store';
import DealerEvents from '../views/DealerEvents/DealerEvents.vue';
// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')
const Vehicle360 = () => import('@/views/Vehicle360/Vehicle360')
const VehiclePriceUpdate = () => import('@/views/VehiclePriceUpdate/VehiclePriceUpdate')
const AccessoriesUpload = () => import('@/views/AccessoriesUpload/AccessoriesUpload')
// Views
const Dashboard = () => import('@/views/Dashboard')
const Login = () => import('@/views/login/Login')
const FileImports = () => import('@/views/FileImports/FileImports')
const Users = () => import('@/views/Users/Users')
const PasswordResetTokens = () => import('@/views/PasswordResetTokens/PasswordResetTokens')
const FloorVehicleAttributeStores = () => import('@/views/FloorVehicleAttributeStores/FloorVehicleAttributeStores')
const CommercialVehicleCategories = () => import('@/views/CommercialVehicleCategories/CommercialVehicleCategories')
const ContentBlocks = () => import('@/views/ContentBlocks/ContentBlocks')
const VehicleCategories = () => import('@/views/VehicleCategories/VehicleCategories')
const VehicleModels = () => import('@/views/VehicleModels/VehicleModels')
const Vehicles = () => import('@/views/Vehicles/Vehicles')
const SecondHandVehicles = () => import('@/views/SecondHandVehicles/SecondHandVehicles')
const HomePageLinks = () => import('@/views/HomePageLinks/HomePageLinks')
const BannerLayouts = () => import('@/views/BannerLayouts/BannerLayouts')
const Dealers = () => import('@/views/Dealers/Dealers')
const PromotionBanners = () => import('@/views/PromotionBanners/PromotionBanners')
const VehicleContentBlocks = () => import('@/views/VehicleContentBlocks/VehicleContentBlocks')
const VehiclePromotionBanners = () => import('@/views/VehiclePromotionBanners/VehiclePromotionBanners')
const VehicleVideos = () => import('@/views/VehicleVideos/VehicleVideos')
const VehicleVariants = () => import('@/views/VehicleVariants/VehicleVariants')
const FleetSections = () => import('@/views/FleetSections/FleetSections')
const Enquiries = () => import('@/views/Enquiries/Enquiries')
const PromotionContentBlocks = () => import('@/views/PromotionContentBlocks/PromotionContentBlocks')
const DealerTeamMembers = () => import('@/views/DealerTeamMembers/DealerTeamMembers')
const ServiceBookings = () => import('@/views/ServiceBookings/ServiceBookings')
const VehicleThreeSixtyImages = () => import('@/views/VehicleThreeSixtyImages/VehicleThreeSixtyImages')
const TestDriveBookings = () => import('@/views/TestDriveBookings/TestDriveBookings')
const Promotions = () => import('@/views/Promotions2/Promotions2')
const OwnerServicesBanners = () => import('@/views/OwnerServicesBanners/OwnerServicesBanners')
const SuggestedProducts = () => import('@/views/SuggestedProducts/SuggestedProducts')
const GlobalSettings = () => import('@/views/GlobalSettings/GlobalSettings')
const BrochureUpload = () => import('@/views/BrochureUpload/BrochureUpload')
const FleetBanners = () => import('@/views/FleetBanners/FleetBanners')
const PreOwnedBanners = () => import('@/views/PreOwnedBanners/PreOwnedBanners')
const Accessories = () => import('@/views/Accessories/Accessories')
const DealerPromotions = () => import('@/views/DealerPromotions/DealerPromotions')
const PageSeos = () => import('@/views/PageSeos/PageSeos')
const DealershipUsers = () => import('@/views/DealershipUsers/DealershipUsers')
const VehicleGalleryImages = () => import('@/views/VehicleGalleryImages/VehicleGalleryImages')

const NewsFeedPromotionBanners = () => import('@/views/PromotionBanners/PromotionBanners')
const NewsFeedItems = () => import('@/views/NewsFeedItems/NewsFeedItems')
const NewsFeedGalleryItems = () => import('@/views/NewsFeedGalleryItems/NewsFeedGalleryItems')


const CorporateSalesPromotionBanners = () => import('@/views/PromotionBanners/PromotionBanners')

const OtherBanners = () =>import('@/views/OtherBanners/OtherBanners')

const SearchIndex = () =>import('@/views/SearchIndex/SearchIndex')
Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [{
    path: '/',
    redirect: '/login',
    name: 'Home',
    component: DefaultContainer,
    children: [{
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      {
        path: 'FileImports',
        name: 'FileImports',
        component: FileImports
      },
      {
        path: 'Vehicle360',
        name: 'Vehicle360',
        component: Vehicle360
      },
      {
        path: 'VehiclePriceUpdate',
        name: 'VehiclePriceUpdate',
        component: VehiclePriceUpdate
      },
      {
        path: 'AccessoriesUpload',
        name: 'AccessoriesUpload',
        component: AccessoriesUpload
      },
      
      {
        path: 'Users',
        name: 'Users',
        component: Users
      },
      {
        path: 'PasswordResetTokens',
        name: 'PasswordResetTokens',
        component: PasswordResetTokens
      },
      {
        path: 'FloorVehicleAttributeStores',
        name: 'FloorVehicleAttributeStores',
        component: FloorVehicleAttributeStores
      },
      {
        path: 'CommercialVehicleCategories',
        name: 'CommercialVehicleCategories',
        component: CommercialVehicleCategories
      },
      {
        path: 'ContentBlocks',
        name: 'ContentBlocks',
        component: ContentBlocks
      },
      {
        path: 'ContentBlocks/:section',
        name: 'ContentBlocksFiltered',
        component: ContentBlocks
      },
      {
        path: 'VehicleCategories',
        name: 'VehicleCategories',
        component: VehicleCategories
      },
      {
        path: 'VehicleModels',
        name: 'VehicleModels',
        component: VehicleModels
      },
      {
        path: 'Vehicles',
        name: 'Vehicles',
        component: Vehicles
      },
      {
        path: 'SecondHandVehicles',
        name: 'SecondHandVehicles',
        component: SecondHandVehicles
      },
      {
        path: 'HomePageLinks',
        name: 'HomePageLinks',
        component: HomePageLinks
      },
      {
        path: 'BannerLayouts',
        name: 'BannerLayouts',
        component: BannerLayouts
      },
      {
        path: 'Dealers',
        name: 'Dealers',
        component: Dealers
      },
      {
        path: 'PromotionBanners',
        name: 'PromotionBanners',
        component: PromotionBanners
      },
      {
        path: 'VehicleContentBlocks',
        name: 'VehicleContentBlocks',
        component: VehicleContentBlocks
      },
      {
        path: 'VehiclePromotionBanners',
        name: 'VehiclePromotionBanners',
        component: VehiclePromotionBanners
      },
      {
        path: 'VehicleVideos',
        name: 'VehicleVideos',
        component: VehicleVideos
      },
      {
        path: 'VehicleVariants',
        name: 'VehicleVariants',
        component: VehicleVariants
      },
      {
        path: 'FleetSections',
        name: 'FleetSections',
        component: FleetSections
      },
      {
        path: 'Enquiries',
        name: 'Enquiries',
        component: Enquiries
      },
      {
        path: 'PromotionContentBlocks',
        name: 'PromotionContentBlocks',
        component: PromotionContentBlocks
      },
      {
        path: 'DealerTeamMembers',
        name: 'DealerTeamMembers',
        component: DealerTeamMembers
      },
      {
        path: 'ServiceBookings',
        name: 'ServiceBookings',
        component: ServiceBookings
      },
      {
        path: 'VehicleThreeSixtyImages',
        name: 'VehicleThreeSixtyImages',
        component: VehicleThreeSixtyImages
      },
      {
        path: 'TestDriveBookings',
        name: 'TestDriveBookings',
        component: TestDriveBookings
      },
      {
        path: 'Promotions',
        name: 'Promotions',
        component: Promotions
      },
      {
        path: 'OwnerServicesBanners',
        name: 'OwnerServicesBanners',
        component: OwnerServicesBanners
      },
      {
        path: 'SuggestedProducts',
        name: 'SuggestedProducts',
        component: SuggestedProducts
      },
      {
        path: 'GlobalSettings',
        name: 'GlobalSettings',
        component: GlobalSettings
      },
      {
        path: 'BrochureUpload',
        name: 'BrochureUpload',
        component: BrochureUpload
      },
      {
        path: 'FleetBanners',
        name: 'FleetBanners',
        component: FleetBanners
      },
      {
        path: 'PreOwnedBanners',
        name: 'PreOwnedBanners',
        component: PreOwnedBanners
      },
      {
        path: 'Accessories',
        name: 'Accessories',
        component: Accessories
      },
      {
        path: 'DealerPromotions',
        name: 'DealerPromotions',
        component: DealerPromotions
      },
      {
        path: 'PageSeos',
        name: 'PageSeos',
        component: PageSeos
      },
      {
        path: 'DealershipUsers',
        name: 'DealershipUsers',
        component: DealershipUsers
      },
      {
        path: 'VehicleGalleryImages',
        name: 'VehicleGalleryImages',
        component: VehicleGalleryImages
      },
      {
        path: 'NewsFeedPromotionBanners',
        name: 'NewsFeedPromotionBanners',
        props: { section: 'News' },
        component: NewsFeedPromotionBanners
      },
      {
        path: 'NewsFeed/:section',
        name: 'NewsFeed',
        component: NewsFeedItems
      },
      {
        path: 'NewsFeedGalleryItems',
        name: 'NewsFeedGalleryItems',
        component: NewsFeedGalleryItems
      },
      {
        path: 'CorporateSalesPromotionBanners',
        name: 'CorporateSalesPromotionBanners',
        props: { section: 'CorporateSales' },
        component: CorporateSalesPromotionBanners
      },
      {
        path: 'OtherBanners',
        name: 'OtherBanners',
        props: { section: 'OtherBanners' },
        component: OtherBanners
      },
      {
        path: 'SearchIndex',
        name: 'SearchIndex',        
        component: SearchIndex
      },
      {
        path: 'DealerEvents',
        name: 'DealerEvents',        
        component: DealerEvents
      },
    ]
  }]
})
router.beforeEach((to, from, next) => {
  if (to.path != '/login') {
    if (!store.state.loginData) {
      next('/login')
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
