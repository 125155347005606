// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbvue/build/css/mdb.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


import './polyfill'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router/index'
import axios from 'axios';
import VueAxios from 'vue-axios';

library.add(fas)

Vue.filter('price', (val) => {
  if (val) {
    val = '' + val;
    return 'R ' + val.split('R').join('').replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ')
  }
  return ''
})

Vue.filter('defaultValue', (val) => {
  if (val) {
    if (val.length > 0)
      return val
  }
  return '-'
})

Vue.filter('unCamelCase', (str) => {
  var upperCase = function (str) {
    return str.toUpperCase();
  }
  var lowerCase = function (str) {
    return str.toLowerCase();
  }
  var properCase = function (str) {
    return lowerCase(str).replace(/^\w|\s\w/g, upperCase);
  }


  if (str) {
    str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2');
    str = str.toLowerCase(); //add space between camelCase text

    return properCase(str);
  }
  return ''
})

Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.config.productionTip = false;

import { store } from '@/store/store';
import { utils } from '@/shared/utils';



// todo
// cssVars()

Vue.use(BootstrapVue)
Vue.use(VueAxios, axios);
/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  utils: utils,
  router,
  template: '<App/>',
  components: {
    App
  },
  methods:{
    isNull: utils.isNull,
    isNullOrEmpty: utils.isNullOrEmpty,
  }
})
